import type { ISbStoriesParams } from '@storyblok/js'
import { useErrorReporter } from '@/composables/useErrorReporter'

export function useStoryblokWithErrorReporting () {
	const { reportError } = useErrorReporter(useBugsnag().notify)
	const getStory = async (endpoint: string, params: ISbStoriesParams) => {
		const story = await useAsyncStoryblok(endpoint, params)
		if (!story?.value) {
			reportError('Storyblok did not return an story')
			throw createError({
				statusCode: 404,
				statusMessage: 'Page Not Found',
				fatal: true
			})
		}
		return story
	}

	return { getStory }
}
